import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CacheService } from 'src/app/_core/_services/cache.service';
import { PaymentGatewayService } from 'src/app/features/portal/_services/payment-gateway.service';
import { PortalAuthenticationService } from 'src/app/features/portal/_services/portal-authentication.service';

@Component({
  selector: 'app-portal-purchase',
  templateUrl: './portal-purchase.component.html',
  styleUrls: ['./portal-purchase.component.scss'],
})
export class PortalPurchaseComponent implements OnInit {
  items: any[] = [];
  activeStepIndex: number = 0;
  activePackageIndex: number = 0;
  packages: any;
  packages$: any;
  otp: any;

  selectedPackage: any;

  selectablePackage: boolean = true;

  createSubscriptionDetailsForm: FormGroup<any> = new FormGroup({
    date_start: new FormControl('', Validators.required),
    preferred_payment_method: new FormControl('', Validators.required),
    subscription_initialisation_type: new FormControl('', Validators.required),
    billing_auto_renew: new FormControl(false, Validators.required),
    portal_product_id: new FormControl('', Validators.required),
    portal_end_customer_id: new FormControl('', Validators.required),
    application_type: new FormControl('manual', Validators.required),
    device_reference: new FormControl(''),
  });
  paymentTypes: any[] = [
    { label: 'Credit Card', value: 'creditcard' },
    // { label: 'Debit Order', value: 'debit_order' },
    // { label: 'EFT', value: 'eft' },
    { label: 'Cash', value: 'cash' },
  ];

  applicationTypes: any[] = [
    { label: 'Order Now', value: 'manual' },
    { label: 'Sales Agent', value: 'salesagent' },
  ];

  initialisationTypes: any[] = [
    { label: 'Once-off', value: 'onceoff' },
    {
      label: 'Once-off and first month recurring',
      value: 'onceoff_and_recurring',
    },
  ];
  portalUser: any;
  vno: any;

  constructor(
    public config: DynamicDialogConfig,

    private route: ActivatedRoute,
    private router: Router,
    public pgService: PaymentGatewayService,
    private portalAuthenticationService: PortalAuthenticationService,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private cacheService: CacheService,
  ) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.packages = this.config.data.packages;
      console.log('Packages: ', this.packages);
      this.packages$ = this.config.data.packages$;
      console.log('Packages$: ', this.packages$);
      this.activeStepIndex = this.config.data.activeIndex;
      this.vno = this.config.data.vno;
      this.portalUser = this.cacheService.getItem('portalUser');
    } else {
      window.alert('No packages found here');
    }
    this.items = [
      {
        label: 'Select Package',
        routerLink: 'portal/purchase/package',
      },
      {
        label: 'Payment Details',
        routerLink: 'portal/purchase/payment',
      },
      {
        label: 'Confirmation',
        routerLink: 'portal/purchase/confirmation',
      },
    ];
  }

  formatDate(date: Date) {
    // dd-mm-yyyy

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Zero-pad the month
    const day = date.getDate().toString().padStart(2, '0'); // Zero-pad the day

    const formattedDate = year + '-' + month + '-' + day;

    return formattedDate;
  }

  getExpireDate(duration_days: number) {
    const date = new Date();
    date.setDate(date.getDate() + duration_days);
    return date;
  }

  onStepActiveIndexChange($event: any) {
    this.activeStepIndex = $event;
  }

  addPackageToCart($event: any, packageItem: any) {
    this.selectablePackage = false;
    this.selectedPackage = packageItem;
  }

  redirectCheckout() {
    this.router.navigate(['/portal/redirect']).finally(() => {
      this.ref.close();
    });
  }

  initiateCheckout() {
    // if (this.portalAuthenticationService.isPortalLoggedIn === false) {
    if (false) {
      // fixme: portal - redirect to login - this should be implemented
      this.ref.close();
      console.log('Not logged in, redirecting to signin');
      this.router.navigate(['/portal/signin']);
    } else {
      this.activeStepIndex = 1;
      this.router.navigate(['/portal/purchase/payment']);

      console.log(this.portalAuthenticationService.portalUserValue);

      this.createSubscriptionDetailsForm.patchValue({
        // date_start: '2023-11-11',
        // payment_method: 'cc',
        // auto_renew: true,
        portal_product_id: this.selectedPackage.id,
        portal_end_customer_id:
          this.portalAuthenticationService.portalUserValue
            .portal_end_customer_id,
      });
    }
  }

  clearSelection() {
    this.selectablePackage = true;
    this.selectedPackage = null;
  }

  onSubscriptionCreateSubmit() {
    if (this.cacheService.getItem('mac') >= '') {
      this.createSubscriptionDetailsForm.patchValue({
        device_reference: this.cacheService.getItem('mac'),
      });
    }
    console.log('Form valid: ', this.createSubscriptionDetailsForm.valid);
    console.log('Form values: ', this.createSubscriptionDetailsForm.value);
    console.log('Original form values: ', this.createSubscriptionDetailsForm);
    console.log('Form errors: ', this.createSubscriptionDetailsForm.errors);

    if (this.createSubscriptionDetailsForm.valid) {
      console.log('Form is realy valid');

      const createSubscriptionDetailsFormValue =
        this.createSubscriptionDetailsForm.value;
      createSubscriptionDetailsFormValue.date_start = this.formatDate(
        this.createSubscriptionDetailsForm.value.date_start,
      );

      this.pgService
        .createSubscription(this.createSubscriptionDetailsForm.value)
        .subscribe((response) => {
          // console.log('Response: ', response);
          // todo: portal - determine what to do next, need to create payment details and also need to redirect to payment gateway
          if (response.body && response.body.paymentRedirect) {
            window.location.href = response.body.paymentRedirect;
          }
          this.ref.close();
        });
    }
  }
}
