<form [formGroup]="upgradeSubscriptionForm" (ngSubmit)="onUpgradeSubscriptionSumbit()">
    <div class="grid formgrid p-fluid justify-content-center align-items-center">
        <div class="field w-12 col-12 md:col-6">
            <label class="text-lg font-medium" for="">Current Package</label>
            <!-- product -->
            <div class="w-full border-1 border-round border-blue-300">
                <div class="align-items-center surface-border grid p-3">
                    <span class="text-xl text-900 font-medium mb-2 text-center col-12">{{
                        currentPackage.name
                        }}</span>
                    <span class="text-md text-400 font-normal mb-2 text-center col-12">{{
                        currentPackage.description
                        }}</span>
                    <div class="col-6 text-center">
                        <span class="text-blue-500 font-medium text-center text-md"><i class="pi pi-arrow-down"> </i>
                            {{ currentPackage.speed_down_mbps }} Mbps</span>
                        <br />
                        <span class="text-600 font-medium text-center">Download </span>
                    </div>

                    <div class="col-6 text-center">
                        <span class="text-green-500 font-medium text-center text-lg"><i class="pi pi-arrow-up"> </i>{{
                            currentPackage.speed_up_mbps }} Mbps
                        </span>
                        <br />
                        <span class="text-600 font-medium text-center">Upload </span>
                    </div>
                    <span class="text-2xl text-green-500 block font-semibold text-center col-12 mb-2">{{
                        currentPackage.recurring_price_incl
                        | currency: "ZAR" : "symbol-narrow"
                        }}</span>
                </div>
            </div>
        </div>
        <div class="field w-12 col-12 md:col-6">
            <label class="text-lg font-medium" for="action_type">Selected Package</label>
            <p-dropdown formControlName="upgrade_product_id" [options]="packages" [showClear]="true" appendTo="body"
                placeholder="Select a product for upgrade" optionLabel="name" optionValue="id" optionDisabled="disable"
                [(ngModel)]="selectedPackage">
                <ng-template let-selectedProduct pTemplate="selectedItem">
                    <div class="w-full border-1 border-round border-blue-300">
                        <div class="align-items-center surface-border grid p-3">
                            <span class="text-xl text-900 font-medium mb-2 text-center col-12">{{ selectedProduct.name
                                }}</span>
                            <span class="text-md text-400 font-normal mb-2 text-center col-12">{{
                                selectedProduct.description }}</span>
                            <div class="col-6 text-center">
                                <span class="text-blue-500 font-medium text-center text-md"><i class="pi pi-arrow-down">
                                    </i>
                                    {{ selectedProduct.speed_down_mbps }} Mbps</span>
                                <br />
                                <span class="text-600 font-medium text-center">Download</span>
                            </div>

                            <div class="col-6 text-center">
                                <span class="text-green-500 font-medium text-center text-lg"><i class="pi pi-arrow-up">
                                    </i>{{ selectedProduct.speed_up_mbps }} Mbps
                                </span>
                                <br />
                                <span class="text-600 font-medium text-center">Upload</span>
                                <span></span>
                            </div>
                            <span class="text-2xl text-green-500 block font-semibold text-center col-12 mb-2">{{
                                selectedProduct.recurring_price_incl
                                | currency: "ZAR" : "symbol-narrow"
                                }}</span>
                        </div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

        <!-- <div class="field w-12 col-12 md:col-6" *ngIf="selectedPackage">


            <div class="w-full border-1 border-round border-blue-300">
                <div class="align-items-center surface-border grid p-3">
                    <span class="text-xl text-900 font-medium mb-2 text-center col-12">Price Difference</span>
                    <span class="text-2xl text-green-500 block font-semibold text-center col-12 mb-2">{{
                        (selectedPackage.recurring_price_incl - currentPackage.recurring_price_incl)
                        | currency: "ZAR" : "symbol-narrow"
                        }}</span>
                </div>
            </div>
        </div> -->

        <button pButton type="submit" label="Upgrade Subscription" class="p-button p-button-success"></button>
    </div>
</form>