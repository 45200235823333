import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PortalPackagesService } from 'src/app/features/portal/_services/portal-packages.service';

@Component({
  selector: 'app-subscriptions-upgrade-dialog',
  templateUrl: './subscriptions-upgrade-dialog.component.html',
  styleUrls: ['./subscriptions-upgrade-dialog.component.scss'],
})
export class SubscriptionsUpgradeDialogComponent implements OnInit {
  selectedPackage: any;
  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,

    public pps: PortalPackagesService,
  ) {}

  packages = this.pps.packages
    .sort((a, b) => a.speed_down_mbps - b.speed_down_mbps)
    .map((p) => {
      return {
        ...p,
        disable: p.id === this.config.data.subscription.portal_product_id,
      };
    });

  currentPackage: any;

  upgradeSubscriptionForm: FormGroup<any> = this.fb.group({
    subscription_id: ['', Validators.required],
    upgrade_product_id: ['', Validators.required],
  });

  ngOnInit(): void {
    this.pps.getPortalPackages();

    this.upgradeSubscriptionForm.patchValue({
      subscription_id: this.config.data.subscription.id,
    });

    this.currentPackage = this.packages.find(
      (p) => p.id === this.config.data.subscription.portal_product_id,
    );

    console.log(this.currentPackage);
  }

  onUpgradeSubscriptionSumbit() {
    this.ref.close();
  }
}
