import { Injectable } from '@angular/core';
import { ApiService } from '../../../_core/_services/api.service';
import { Observable, Subject, map } from 'rxjs';
import {
  PortalProductsCreate,
  PortalProductsUpdate,
} from 'src/app/_share/_models/portal/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PortalPackagesService {
  constructor(private api: ApiService) {}

  packages: any[] = [];
  packages$: Subject<any[]> = new Subject<any[]>();
  packagesQuery$: any;

  getPortalPackagesManagement() {
    return this.api.getAPI('portal/management/products').pipe(
      map((res: any) => {
        this.packages = res.body.data;
        this.packages$.next(this.packages);
        return res.body.data;
      }),
    );
  }

  getPortalPackages() {
    return this.api.getAPI('portal/products').pipe(
      map((res: any) => {
        // console.log('how you like dem packages', res);
        this.packages = res.body;
        this.packages$.next(this.packages);
        return res.body.data;
      }),
    );
  }

  createPortalPackage(
    data: PortalProductsCreate,
  ): Observable<PortalProductsCreate> {
    return this.api.postAPI('portal/products', data).pipe(
      map((res: any) => {
        return res.body;
      }),
    );
  }

  updatePortalPackage(id: number, data: PortalProductsUpdate) {
    return this.api.putAPI('portal/products', id, data);
  }
}
