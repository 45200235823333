<p-steps #pSteps [model]="items" [readonly]="true" [(activeIndex)]="activeStepIndex"></p-steps>

<!-- activeStepIndex === 0 -->
<div *ngIf="activeStepIndex === 0">
  <h2>Choose a package</h2>
  <p-tabView [activeIndex]="activePackageIndex" (onChange)="activePackageIndex = $event.index" class="p-mt-4 m-5">
    <!-- fixed -->
    <p-tabPanel header="Fixed"><!-- pricing table in a single tab -->

      <div class="surface-section px-4 py-8 md:px-6 lg:px-8" style="overflow-y: auto; max-height: 50vh; height: 40vh">
        <div class="grid -mt-3 -ml-3 -mr-3">
          <ng-container *ngFor="let package of (packages$ | async)">
            <div *ngIf="package.product_type == 'fixed'"
              class="col-12 md:col-6 lg:col-4 xl:col-3 border-bottom-1 surface-border md:border-bottom-none">
              <div class="p-2">
                <div [class.border-2]="selectedPackage === package"
                  [class.border-blue-400]="selectedPackage === package" [class.border-1]="selectedPackage !== package"
                  [class.border-green-100]="selectedPackage !== package"
                  class="text-center py-4 border-round border-radius-4">
                  <div class="text-900 text-xl font-medium mb-2">
                    {{ package.name }}
                  </div>
                  <div class="text-blue-500 text-xl font-medium mb-2">
                    {{ package.speed }}
                  </div>
                  <div class="mb-2">
                    <span>{{ package.description }}</span>
                    <br />
                    <br />
                    <span class="text-600"> Installation fee once off:</span>
                    <br />
                    <span class="text-green-600 font-semibold text-lg">
                      {{ package.onceoff_price_incl | currency : "R" }}</span>
                    <br />
                    <br />
                    <span class="text-600">Monthly:</span>
                    <br />
                    <span class="text-600">{{ package.recurring_price_excl | currency : "R" }} (excl
                      VAT)</span>
                    <br />
                    <span class="text-green-600 font-semibold text-lg">{{ package.recurring_price_incl | currency : "R"
                      }} (incl
                      VAT)</span>
                    <br />
                  </div>
                  <div class="flex flex-column align-content-end">
                    <p-button icon="pi pi-plus" styleClass="p-button-rounded mt-2" [disabled]="!selectablePackage"
                      label="Select Package" (onClick)="addPackageToCart($event, package)"></p-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </p-tabPanel>
    <!-- bi monthly -->
    <!-- <p-tabPanel header="Bi-Monthly">
      <div
        class="surface-section px-4 py-8 md:px-6 lg:px-8"
        style="overflow-y: auto; max-height: 50vh; height: 50vh"
      >
        <div class="grid -mt-3 -ml-3 -mr-3">
          <ng-container *ngFor="let package of packages$ | async">
            <div
              *ngIf="package.type == 'bimonthly'"
              class="col-12 md:col-6 lg:col-4 xl:col-3 border-bottom-1 surface-border md:border-bottom-none"
            >
              <div class="p-2">
                <div
                  [class.border-2]="selectedPackage === package"
                  [class.border-blue-400]="selectedPackage === package"
                  [class.border-1]="selectedPackage !== package"
                  [class.border-green-100]="selectedPackage !== package"
                  class="text-center py-4 border-round border-radius-4"
                >
                  <div class="text-900 text-xl font-medium mb-2">
                    {{ package.name }}
                  </div>
                  <div class="text-blue-500 text-xl font-medium mb-2">
                    {{ package.speed }}
                  </div>
                  <div class="mb-2">
                    <span>{{ package.description }}</span>
                    <br />
                    <br />
                    <br />
                    <span class="text-600"
                      >{{ package.price_excl | currency : "R" }} (excl
                      VAT)</span
                    >
                    <br />
                    <span class="text-green-600 font-semibold text-lg"
                      >{{ package.price_incl | currency : "R" }} (incl
                      VAT)</span
                    >
                    <br />
                  </div>
                  <div class="flex flex-column align-content-end">
                    <p-button
                      icon="pi pi-plus"
                      styleClass="p-button-rounded mt-2"
                      [disabled]="!selectablePackage"
                      label="Select Package"
                      (onClick)="addPackageToCart($event, package)"
                    ></p-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div></div
    ></p-tabPanel> -->
    <!-- once off packages -->
    <p-tabPanel header="Hotspot"><!-- pricing table in a single tab -->

      <div class="surface-section px-4 py-8 md:px-6 lg:px-8" style="overflow-y: auto; max-height: 50vh; height: 40vh">
        <div class="grid -mt-3 -ml-3 -mr-3">
          <ng-container *ngFor="let package of (packages$ | async)">
            <div *ngIf="package.product_type == 'hotspot'"
              class="col-12 md:col-6 lg:col-4 xl:col-3 border-bottom-1 surface-border md:border-bottom-none">
              <div class="p-2">
                <div [class.border-2]="selectedPackage === package"
                  [class.border-blue-400]="selectedPackage === package" [class.border-1]="selectedPackage !== package"
                  [class.border-green-100]="selectedPackage !== package"
                  class="text-center py-4 border-round border-radius-4">
                  <div class="text-900 text-xl font-medium mb-2">
                    {{ package.name }}
                  </div>
                  <div class="text-blue-500 text-xl font-medium mb-2">
                    {{ package.speed }}
                  </div>
                  <div class="mb-2">
                    <span>{{ package.description }}</span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <span class="text-900 text-lg">Once-off:</span>
                    <br />
                    <br />
                    <span class="text-600">{{ package.onceoff_price_excl | currency : "R" }} (excl
                      VAT)</span>
                    <br />
                    <span class="text-green-600 font-semibold text-lg">{{ package.onceoff_price_incl | currency : "R" }}
                      (incl
                      VAT)</span>
                    <br />
                  </div>
                  <div class="flex flex-column align-content-end">
                    <p-button icon="pi pi-plus" styleClass="p-button-rounded mt-2" [disabled]="!selectablePackage"
                      label="Select Package" (onClick)="addPackageToCart($event, package)"></p-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <div class="flex flex-row justify-content-center border-top-1 border-gray-300">
    <!-- selected package for checkout component -->
    <div class="flex align-self-center" *ngIf="selectedPackage">
      <ul class="list-none m-0 p-0">
        <li class="py-3 flex align-items-start sm:align-items-center">
          <div class="pl-3 flex-auto">
            <div class="flex align-items-center justify-content-between mb-3">
              <span class="font-medium text-600 mr-2">You have selected </span><span class="font-medium text-900">{{
                selectedPackage.name
                }}</span>
              <span class="font-bold text-blue-700 ml-1">{{
                selectedPackage.price_incl | currency : "R"
                }}</span>
            </div>
            <div class="text-600 text-sm mb-3">
              {{ selectedPackage.speed }} -
              {{ selectedPackage.description }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="flex align-self-center">
      <button pButton class="p-button-success mx-2 my-2" label="Continue to checkout" (click)="initiateCheckout()"
        [disabled]="selectablePackage"></button>
    </div>
    <div class="flex align-self-center">
      <button *ngIf="!selectablePackage" pButton class="p-button-danger mx-2 my-2" label="Clear Selection"
        icon="pi pi-times" (click)="clearSelection()"></button>
    </div>
  </div>
</div>

<div *ngIf="activeStepIndex === 1">
  <div class="flex flex-row">
    <div class="flex-1 align-self-center py-3" *ngIf="selectedPackage">
      <div class="p-4">
        <div class="border-bottom-1 surface-border pb-4">
          <span class="text-900 text-xl block">Subscription Summary</span>
        </div>
        <div class="border-bottom-1 surface-border my-3 py-2">
          <div class="flex justify-content-between mb-3">
            <span class="text-900">Once Off (incl VAT) -
              {{ selectedPackage.onceoff_description }}</span>
            <span class="text-green-500">{{
              selectedPackage.onceoff_price_incl | currency : "R"
              }}</span>
          </div>
          <div class="flex justify-content-between mb-3" *ngIf="selectedPackage.recurring_price_incl > 0">
            <span class="text-900">Monthly (incl VAT) -
              {{ selectedPackage.recurring_description }}</span>
            <span class="text-green-500">{{
              selectedPackage.recurring_price_incl | currency : "R"
              }}</span>
          </div>
          <!-- <div class="flex justify-content-between mb-3">
            <span class="text-900">Discount</span>
            <span class="text-900">$12.00</span>
          </div> -->
        </div>
        <div class="border-bottom-1 surface-border my-3 py-2">
          <div class="flex justify-content-between mb-3">
            <span class="text-900 font-medium">Total Due Today</span>
            <span class="text-900 font-bold">{{
              selectedPackage.onceoff_price_incl | currency : "R"
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-row flex-1">
    <form [formGroup]="createSubscriptionDetailsForm" (ngSubmit)="onSubscriptionCreateSubmit()">
      <div class="border-bottom-1 surface-border p-4">
        <span class="text-900 text-xl block">Payment Details</span>
      </div>
      <div class="grid formgrid p-fluid p-4">
        <!-- date start -->
        <div class="field col-12 md:col-6">
          <label for="date_start">Start Date</label>
          <p-calendar class="col-6" formControlName="date_start" [touchUI]="true" view="date"
            dateFormat="dd-mm-yy"></p-calendar>
        </div>

        <div class="field mb-4 col-12 md:col-6">
          <label for="payment_method" class="font-medium text-900">Payment Type</label>
          <p-dropdown id="" [options]="paymentTypes" optionLabel="label" optionValue="value"
            placeholder="Select a payment type" formControlName="preferred_payment_method"></p-dropdown>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="application_type" class="font-medium text-900">Application Type</label>
          <p-dropdown id="application_type" [options]="applicationTypes" optionLabel="label" optionValue="value"
            placeholder="Select an application type" formControlName="application_type"></p-dropdown>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="application_type" class="font-medium text-900">Subscription Initialisation Type</label>
          <p-dropdown id="application_type" [options]="initialisationTypes" optionLabel="label" optionValue="value"
            placeholder="Select an initialisation type" formControlName="subscription_initialisation_type"></p-dropdown>
        </div>

        <div class="field mb-4 col-12 md:col-6">
          <p-checkbox id="billing_auto_renew" [binary]="true" [value]="false" formControlName="billing_auto_renew"
            label="Auto Renew?"></p-checkbox>
          <!-- <small for="billing_auto_renew" class="block"
          >Were you on a call for longer than 30 seconds?</small
        > -->
        </div>
        <input pInputText formControlName="portal_product_id" hidden />
        <input pInputText formControlName="portal_end_customer_id" hidden />

        <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
        <div class="col-12">
          <button pButton pRipple label="Continue to Payment" class="w-auto mt-3" type="submit"></button>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="activeStepIndex === 2">
  <!-- user receives an OTP/voucher code and enters it here to enable access -->

  <div class="flex flex-row">
    <div class="flex-1 justify-content-center">
      <h3>Your payment was SUCCESSFUL!</h3>
    </div>
    <!-- <div class="flex">
      <h3>Enter your OTP code</h3>
    </div>
    <div class="flex">
      <input
        pInputText
        type="text"
        name="otp"
        [(ngModel)]="this.otp"
        placeholder="Enter your OTP code"
      />
    </div>
    <div class="flex">
      <button
        pButton
        class="p-button-primary"
        label="Submit OTP"
        (click)="submitOtp()"
      ></button>
    </div>
    <div class="flex">
      <button pButton class="p-button-primary" label="Resend OTP"></button>
    </div> -->
  </div>
</div>