<!-- <div class="text-900 font-medium text-xl mb-3">Update Device Configuration</div> -->

<form [formGroup]="billingRunForm" (ngSubmit)="onSubmit()">
    <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 md:col-12">
            <label for="name" class="font-medium text-900">Billing Run Name</label>
            <input id="name" formControlName="name" pInputText />
        </div>
        <div class="field mb-4 col-12 md:col-6 ">
            <label for="date_period_start" class="font-medium text-900">Billing Period Start Date</label>
            <p-calendar id="date_period_start" formControlName="date_period_start" appendTo="body" dataType="string"
                dateFormat="yy-mm-dd" [touchUI]="false"></p-calendar>
        </div>
        <div class="field mb-4 col-12 md:col-6">
            <label for="date_period_end" class="font-medium text-900">Billing Period End Date</label>
            <p-calendar id="date_period_end" formControlName="date_period_end" appendTo="body" dateFormat="yy-mm-dd"
                dataType="string" [touchUI]="false"></p-calendar>
        </div>
        <!-- feedback -->
        <div *ngIf="errorMessage !== ''" class="field col-12 align-items-center justify-content-center">
            <p-message severity="error" [text]="errorMessage" styleClass="w-full"></p-message>
        </div>
        <!-- SUBMIT BUTTON -->
        <div class="field col-12">
            <button type="submit" pButton pRipple [disabled]="!billingRunForm.valid" label="Create Billing Run"
                class="w-full"></button>
        </div>
    </div>
</form>