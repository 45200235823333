import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NotificationService } from 'src/app/_share/_services/notification.service';
import { NetworkBillingRunsService } from '../../_services/network-billing-runs.service';

@Component({
  selector: 'app-dialog-billing-run',
  templateUrl: './dialog-billing-run.component.html',
  styleUrls: ['./dialog-billing-run.component.scss'],
})
export class DialogBillingRunComponent implements OnInit {
  errorMessage: string = '';
  constructor(
    private fb: FormBuilder,
    private ref: DynamicDialogRef,
    public notificationService: NotificationService,
    private brs: NetworkBillingRunsService,
  ) {}

  billingRunForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    date_period_start: ['', [Validators.required]],
    date_period_end: ['', [Validators.required]],
    date_run: ['', [Validators.required]],
  });

  ngOnInit(): void {
    this.billingRunForm.patchValue({
      date_run: new Date(),
    });
  }

  onSubmit() {
    if (this.billingRunForm.invalid) {
      this.errorMessage = 'Please fill out all required fields';
      return;
    }
    if (this.billingRunForm.valid) {
      console.log('Form Submitted', this.billingRunForm.value);
      this.brs.createBillingRun(this.billingRunForm.value).subscribe(
        (res) => {
          console.log('Billing Run Created', res);
          this.errorMessage = '';
          this.ref.close(this.billingRunForm.value);
          this.notificationService.mainSuccess(
            'Billing run created successfully',
          );
        },
        (err) => {
          console.error('Error creating new billing run configuration', err);
          this.errorMessage = 'Error creating billing run configuration';
        },
      );
    }
  }
}
