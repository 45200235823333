import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from 'src/app/_share/_services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authenticationService.userValue;
    if (user) {
      // todo: check if route is restricted by role

      this.userService.getCurrentLoggedInUser();

      this.userService.currentUser$.subscribe((currentUser: any) => {
        if (
          route.data['roles'].indexOf(currentUser && currentUser.role.name) ===
          -1
        ) {
          // role not authorised for this route so redirect to appropriate page
          // fixme: if role is not caught, spams users/me and bombs out the back end app
          if (
            currentUser.role.name === 'superadmin' ||
            currentUser.role.name === 'operationsadmin' ||
            currentUser.role.name === 'operationsmanager' ||
            currentUser.role.name === 'ispadmin' ||
            currentUser.role.name === 'vnoadmin'
          ) {
            this.router.navigate(['/']);
          } else if (
            currentUser.role.name === 'salesmanager' ||
            currentUser.role.name === 'salesagent'
          ) {
            this.router.navigate(['/crm']);
          } else if (currentUser.role.name === 'portaladmin') {
            this.router.navigate(['/portal-management']);
          } else if (currentUser.role.name === 'businesssalesagent') {
            this.router.navigate(['/business', 'questions']);
          } else {
            this.router.navigate(['/crm']);
          }
          return false;
        } else {
          return true;
        }
      });

      return true;
    }

    // authorised so return true

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
