<div class="lg:px-6 px-1 py-5">
  <div class="surface-card p-4 shadow-2 border-round">
    <!-- <span class="p-buttonset">
      <button type="button" pButton icon="pi pi-list" label="Show Subscription Actions"
        (click)="overlayPanel.toggle($event)"></button>
    </span>
    <p-overlayPanel #overlayPanel>
      <p-menu #menu [model]="subscriptionActions"></p-menu>
    </p-overlayPanel> -->

    <!-- change anniversary date -->
    <span class="p-buttonset px-1">
      <button type="button" pButton icon="pi pi-calendar" label="Change Anniversary Date"
        (click)="showUpdateSubscriptionDialog(subscription,$event)"></button>
    </span>


    <!-- override activation -->
    <!-- <span class="p-buttonset px-1">
      <button type="button" pButton icon="pi pi-refresh" class="p-button-warning" label="Override Activation"
        (click)="showUpgradeSubscriptionDialog(subscription,$event)"></button>
    </span> -->

    <!-- upgrade subscription  -->
    <span class="p-buttonset px-1">
      <button type="button" pButton icon="pi pi-upload" class="p-button-success p-button-outlined"
        label="Upgrade Subscription" (click)="showUpgradeSubscriptionDialog(subscription,$event)"></button>
    </span>
  </div>

</div>