import { Component, Input, OnInit } from '@angular/core';
import { PortalSubscriptions } from '../../_models/portal/portal-subscriptions.model';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SubscriptionsUpdateDialogComponent } from 'src/app/features/main-application/pages/portal-management/subscriptions/subscriptions-update-dialog/subscriptions-update-dialog.component';
import { SubscriptionsUpgradeDialogComponent } from 'src/app/features/main-application/pages/portal-management/subscriptions/subscriptions-upgrade-dialog/subscriptions-upgrade-dialog.component';

@Component({
  selector: 'share-subscription-action-bar',
  templateUrl: './share-subscription-action-bar.component.html',
  styleUrls: ['./share-subscription-action-bar.component.scss'],
})
export class ShareSubscriptionActionBarComponent implements OnInit {
  @Input() subscription: PortalSubscriptions | undefined;

  subscriptionActions: MenuItem[] = [];

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    if (!this.subscription) {
      throw new Error('Subscription is required');
    } else {
      if (this.subscription.subscription_status === 'new_sales_lead') {
        this.subscriptionActions = [
          {
            label: 'Assign Subscription Package',
            icon: 'pi pi-plus',
            styleClass: 'p-button-success',
            routerLink: ['/subscriptions', this.subscription?.id],
          },
          {
            label: 'Edit Subscription Details',
            icon: 'pi pi-pencil',
            styleClass: 'p-button-success',
            routerLink: ['/subscriptions', this.subscription?.id, 'edit'],
          },
          // {
          //   label:
          // }
        ];
      }
      // if (this.subscription.subscription_status === 'active')
      else {
        this.subscriptionActions = [
          {
            label: 'Update Subscription Billing Date',
            icon: 'pi pi-calendar-plus',
            styleClass: 'p-button-success',

            // routerLink: ['/subscriptions', this.subscription?.id],
            command: (event) => {
              this.showUpdateSubscriptionDialog(this.subscription, event);
            },
          },
          // {
          //   label: 'Upgrade Subscription',
          //   icon: 'pi pi-pencil',
          //   styleClass: 'p-button-danger',
          //   routerLink: ['/subscriptions', this.subscription?.id, 'edit'],
          // },
          // {
          //   label: 'Cancel Subscription',
          //   icon: 'pi pi-times',
          //   styleClass: 'p-button-warning',
          //   routerLink: ['/subscriptions', this.subscription?.id, 'edit'],
          // },
          // {
          //   label: 'Edit Subscription Details',
          //   icon: 'pi pi-pencil',
          //   styleClass: 'p-button-success',
          //   routerLink: ['/subscriptions', this.subscription?.id, 'edit'],
          //   command: (event) => {
          //     console.log(event);
          //   },
          // },
        ];
      }
    }
  }

  showUpdateSubscriptionDialog(rowData: any, event: Event) {
    // Check if the target of the click event was the ellipsis button

    const ref = this.dialogService.open(SubscriptionsUpdateDialogComponent, {
      data: {
        subscription: rowData,
      },
      modal: true,
      showHeader: true,
      header: 'Update Subscription Billing Date',
      width: '60%',
    });

    ref.onClose.subscribe((res) => {
      console.log('Dialog closed', res);
      // this.retrievePortalSubscriptions();
    });
  }
  showUpgradeSubscriptionDialog(rowData: any, $event: Event) {
    console.log('Upgrade Subscription Dialog');

    const ref = this.dialogService.open(SubscriptionsUpgradeDialogComponent, {
      data: {
        subscription: rowData,
      },
      modal: true,
      showHeader: true,
      header: 'Upgrade Subscription',
      width: '60%',
    });

    ref.onClose.subscribe((res) => {
      console.log('Dialog closed', res);
      // this.retrievePortalSubscriptions();
    });
  }
}
