import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { PortalUsersService } from 'src/app/features/portal/_services/portal-users.service';
import { PortalPackagesService } from 'src/app/features/portal/_services/portal-packages.service';

import { ActivatedRoute, Router } from '@angular/router';
import { PortalAuthenticationService } from 'src/app/features/portal/_services/portal-authentication.service';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { CacheService } from 'src/app/_core/_services/cache.service';

// testing stripe
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { ParksService } from 'src/app/features/portal/_services/parks.service';

import { TenantsService } from 'src/app/_share/_services/tenants.service';
import { PortalAuthUkComponent } from '../../components/portal-auth-uk/portal-auth-uk.component';
import { PortalLoginComponent } from '../../components/portal-login/portal-login.component';
import { PortalPurchaseComponent } from '../../components/portal-purchase/portal-purchase.component';
import { PortalRegisterUkComponent } from '../../components/portal-register-uk/portal-register-uk.component';
import { PortalFormComponent } from '../../components/portal-registration/portal-form.component';
import { StatusDialogComponent } from '../../components/status-dialog/status-dialog.component';
import { SuccessfulLoginDialogComponent } from '../../components/successful-login-dialog/successful-login-dialog.component';
import { PortalAuthZaComponent } from '../../components/portal-auth-za/portal-auth-za.component';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent implements OnInit {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  visibleDialog: boolean = false;
  quantities2: number[] = [1, 1];
  portalUser: any;
  isLoading: boolean = false;
  isParkLoading: boolean = false;
  packages: any[] = [];
  packages$: Subject<any[]> = new Subject<any[]>();
  vno: any;

  portalConfig$: BehaviorSubject<any> = new BehaviorSubject<any>({
    logoSrc:
      'https://benington-networks.co.uk/wp-content/uploads/BN-LOGO-3.svg',
    headerColor: '#ffffff',
    headerHeight: '80px',
    logoHeight: 32,
  });

  public cardOptions: StripeCardElementOptions = {
    style: {
      //   base: {
      //     fontWeight: 400,
      //     fontFamily: 'Circular',
      //     fontSize: '14px',
      //     iconColor: '#666EE8',
      //     color: '#002333',
      //     '::placeholder': {
      //       color: '#919191',
      //     },
      //   },
    },
    classes: {
      base: 'p-inputtext',
      complete: 'is-valid',
      empty: 'is-invalid',
      focus: 'is-valid',
      invalid: 'is-invalid',
      webkitAutofill: 'is-valid',
    },
  };

  public elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripe = this.stripeService.stripe;
  park: any;
  park_ip: any;
  parks: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public cacheService: CacheService,
    private http: HttpClient,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private tenantService: TenantsService,
    private parksService: ParksService,
    public dialogService: DialogService,
    public portalUsersService: PortalUsersService,
    private portalAuthenticationService: PortalAuthenticationService,
    public portalPackagesService: PortalPackagesService,
  ) {}

  ngOnInit(): void {
    this.tenantService.getAll();

    this.vno = this.cacheService.getItem('vno');
    this.park = this.cacheService.getItem('ip');

    this.retrievePortalPackages();
    this.portalUsersService.getPortalUsers();
    this.portalUser = this.portalAuthenticationService.portalUser;

    this.route.queryParams.subscribe((params: any) => {
      const vno = params['vno'];
      const mac = params['mac'];
      const park_ip = params['ip'];
      const login = params['login'];
      const status = params['status'];

      if (status) {
        this.showSuccessfulLoginDialog();
      }

      if (park_ip) {
        this.park_ip = park_ip;
        this.cacheService.setItem('ip', park_ip);
      } else {
        this.park_ip = this.cacheService.getItem('ip');
      }

      if (login) {
        this.cacheService.setItem('login', login);
      }

      this.parksService.getParks().subscribe((res: any) => {
        console.log('parks', res);
        this.parks = res;
        this.isParkLoading = true;

        for (const park_to_test of this.parks) {
          if (this.park_ip) {
            if (this.park_ip.includes(park_to_test.external_reference)) {
              this.park = park_to_test;
            }
          }
        }
      });
      if (vno) {
        this.vno = vno;
        this.cacheService.setItem('vno', vno);
      }
      if (this.vno == 'Benington') {
        this.portalConfig$.next({
          logoSrc:
            'https://benington-networks.co.uk/wp-content/uploads/BN-LOGO-3.svg',
          headerColor: '#4FADEA',
          headerHeight: '80px',
          logoHeight: 32,
        });
      } else if (this.vno == 'MTD') {
        this.portalConfig$.next({
          logoSrc:
            // logo for mtd is in assets
            '/assets/portal/images/mtd/logo.png',
          // 'https://mtdnetworx.co.za/wp-content/uploads/2021/05/MTD-X-new-logo-2048x991.png',
          headerColor: '#000',
          headerHeight: '160px',
          logoHeight: 64,
        });
      } else {
        this.portalConfig$.next({
          logoSrc:
            'https://stemconnect.net/za/wp-content/uploads/sites/4/images/logo.png',
          headerColor: '#0e2c45',
          headerHeight: '80px',
          logoHeight: 32,
        });
      }

      console.log('mac', mac, typeof mac);

      if (mac >= '') {
        this.cacheService.setItem('mac', mac);
      }
      // else {
      //   this.cacheService.setItem('mac', '');
      // }
    });

    this.route.params.subscribe((params: any) => {
      const step = params['step'];
      const action = params['action'];

      switch (action) {
        case 'purchase':
          if (!this.dialogService.dialogComponentRefMap.size) {
            break;
          }
          break;
        case 'signup':
          if (!this.dialogService.dialogComponentRefMap.size) {
            this.showSignUpDialog(null);
          }
          break;
        case 'signin':
          if (!this.dialogService.dialogComponentRefMap.size) {
            this.showSignInDialog(null);
            break;
          }
          break;
        default:
          this.router.navigate(['/portal']);
      }

      if (step) {
        console.log('yes there is a step');
        switch (step) {
          case 'package':
            if (!this.dialogService.dialogComponentRefMap.size) {
              this.showPurchaseDialog(null, 0);
            }
            break;
          case 'payment':
            if (!this.dialogService.dialogComponentRefMap.size) {
              this.showPurchaseDialog(null, 1);
            }
            break;
          case 'confirmation':
            if (!this.dialogService.dialogComponentRefMap.size) {
              this.showPurchaseDialog(null, 2);
            }
            break;
          default:
            this.router.navigate(['/portal']);
        }
      } else {
        console.log('no step');
      }

      console.log(action);
      console.log(step);
    });
  }

  retrievePortalPackages() {
    this.isLoading = true;
    this.portalPackagesService.getPortalPackages().subscribe((res: any) => {
      this.packages = res;
      console.log('this is the list of packages', this.packages);
      this.packages$.next(this.packages);
      this.isLoading = false;
    });
  }

  showStatusDialog(
    status: string,
    header: string,
    message: string,
    accountdata: any = null,
  ) {
    this.dialogService.open(StatusDialogComponent, {
      data: {
        status: status,
        header: header,
        message: message,
        accountdata: accountdata,
      },
      header: header,
      width: '80%',
      height: '80%',
      closable: false,
      closeOnEscape: false,
      dismissableMask: false,
    });
  }

  showSignUpDialog($event: any) {
    console.log($event);

    const ref = this.dialogService.open(PortalFormComponent, {
      data: {
        name: 'Test',
        email: '',
      },
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });

    ref.onClose.subscribe((data) => {
      console.log('Data', data);
    });
  }

  showSuccessfulLoginDialog() {
    const successFullLoginDialogRef = this.dialogService.open(
      SuccessfulLoginDialogComponent,
      {},
    );

    successFullLoginDialogRef.onClose.subscribe((data) => {
      console.log('Data', data);
    });
  }

  // new customers
  showRegisterDialogUK($event: any, action: string = 'register') {
    console.log($event);

    let dialogDetails = {
      header: '',
      headerDetails: '',
      park: '',
    };

    if (action === 'register') {
      dialogDetails = {
        header: `Register as a new user at ${this.park.name}`,
        headerDetails: `Please fill in the form below to register as a new user at ${this.park.name}.`,
        park: this.park,
      };
      const dialogRef = this.dialogService.open(PortalRegisterUkComponent, {
        data: {
          dialogDetails: dialogDetails,
          name: '',
          email: '',
        },
        width: '85%',
        closeOnEscape: true,
        keepInViewport: true,
        showHeader: false,
        header: 'Purchase WiFi Access',
      });

      dialogRef.onClose.subscribe((data) => {
        // console.log('form response data', data);

        if (data.success && data.status === 'new-user') {
          this.showStatusDialog(
            'success',
            'Successful',
            'You have successfully registered as a new user at ' +
              this.park.name,
            { ...data },
          );
        } else if (data.success && data.status === 'existing-user') {
          this.showStatusDialog(
            'success',
            'Successful',
            'You have successfully topped up as an existing user at ' +
              this.park.name,
            { ...data },
          );
        }
      });
    } else if (action === 'topup') {
      dialogDetails = {
        header: `Top up your account at ${this.park.name}`,
        headerDetails: `Please enter your account code or email address and choose your topup package`,
        park: this.park,
      };

      const dialogRef = this.dialogService.open(PortalRegisterUkComponent, {
        data: {
          dialogDetails: dialogDetails,
          name: '',
          email: '',
        },
        width: '90%',
        height: '70%',
        closeOnEscape: false,
        keepInViewport: true,

        maximizable: true,
        showHeader: true,
        header: dialogDetails.header,
      });
    } else if (action === 'authenticate') {
      const dialogRef = this.dialogService.open(PortalAuthUkComponent, {
        header: 'Authenticate Device',
        data: {
          name: '',
          email: '',
        },
        width: '90%',
        height: '70%',
        closeOnEscape: true,
      });
    }
  }

  // returning customers
  showLoginDialogUK($event: any) {
    console.log($event);

    const dialogRef = this.dialogService.open(PortalLoginComponent, {
      data: {
        name: '',
        email: '',
      },
      width: '50%',
      closeOnEscape: true,
    });

    dialogRef.onClose.subscribe((data) => {
      console.log('form data', data);
    });
  }

  showAuthDialogUK($event: any) {
    console.log($event);

    const dialogRef = this.dialogService.open(PortalAuthUkComponent, {
      header: 'Authenticate Device',
      data: {
        name: '',
        email: '',
      },
      width: '90%',
      height: '70%',
      closeOnEscape: true,
    });

    dialogRef.onClose.subscribe((data) => {
      console.log('form data', data);
    });
  }

  showAuthDialogZA($event: any) {
    console.log($event);

    const dialogRef = this.dialogService.open(PortalAuthZaComponent, {
      header: 'Activate Internet Voucher',
      data: {
        name: '',
        email: '',
      },
      width: '80%',
      height: '70%',
      closeOnEscape: true,
    });

    dialogRef.onClose.subscribe((data) => {
      console.log('form data', data);
    });
  }

  showSignInDialog($event: any) {
    this.dialogService.open(PortalLoginComponent, {
      data: {
        name: '',
        email: '',
      },
      width: '50%',
      height: '70%',
      closeOnEscape: true,
    });
  }

  showSignOutDialog($event: any) {
    console.log($event);
    this.portalAuthenticationService.logout();
  }

  showPurchaseDialog(
    $event: any,
    activeIndex: number = 0,
    vno: string = 'Stem',
  ) {
    // this.portalPackagesService.getPortalPackages();

    // does it make sense
    const dialogRef = this.dialogService.open(PortalPurchaseComponent, {
      data: {
        name: 'Test',
        email: '',
        vno: vno,
        packages: this.packages,
        packages$: this.packages$,
        activeIndex: activeIndex,
      },
      header: 'Connection Packages',
      width: '90%',
      height: '90%',
      closeOnEscape: true,
      maximizable: true,
    });

    dialogRef.onClose.subscribe(() => {
      // if current route is /portal/purchase/confirmation then redirect to /portal
      if (this.router.url.includes('/portal/purchase')) {
        this.router.navigate(['/portal']);
      }
      // this.router.navigate(['/portal']);
    });
  }

  createPaymentIntent(amount: number): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/portal/create-payment-intent`, {
        amount: amount,
        currency: 'gbp',
      })
      .pipe(
        map((res) => {
          console.log('res', res);
          return res;
        }),
      );
  }

  onCardChange(event: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError!.textContent = event.error.message;
    } else {
      displayError!.textContent = '';
    }
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}
