import { Component, OnInit } from '@angular/core';
import { NetworkPortalProducts } from '../../_models/network-portal-products.model';
import { NetworkBillingRunsService } from '../../_services/network-billing-runs.service';
import { BehaviorSubject } from 'rxjs';
import { NetworkPortalInvoiceLineItem } from '../../_models/network-portal-invoice-line-items.model';
import { NetworkPortalInvoicesService } from '../../_services/network-portal-invoices.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogBillingRunComponent } from '../../components/dialog-billing-run/dialog-billing-run.component';

@Component({
  selector: 'app-services-billing',
  templateUrl: './services-billing.component.html',
  styleUrls: ['./services-billing.component.scss'],
})
export class ServicesBillingComponent implements OnInit {
  selectedBillingRun: any;
  scheduleLineItemsLoading: boolean = false;
  scheduleLineItemsLoading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.scheduleLineItemsLoading);
  downloadLoading: boolean = false;
  constructor(
    private nbrs: NetworkBillingRunsService,
    private nis: NetworkPortalInvoicesService,
    private ds: DialogService,
  ) {}

  billingRuns: any[] = [];

  invoices: any[] = [];
  invoices$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this.invoices);

  inclTotal: number = 0;
  exclTotal: number = 0;

  columns: any[] = [
    { name: 'ISP', field: 'customer' },
    // { name: 'Product', field: 'name' },
    { name: 'External Product Code', field: 'code' },
    // { name: 'Product Type', field: 'product_type' },
    // { name: 'External Product ID', field: 'external_product_id' },
    // { name: 'External Category', field: 'external_category' },
    { name: 'Date', field: 'date' },
    { name: 'Description', field: 'description' },
    // { name: 'Service Ref', field: 'service_ref' },
    { name: 'Price Excl', field: 'amount_excl' },
    { name: 'Price Incl', field: 'amount_incl' },
    { name: 'Currency', field: 'currency' },
    { name: 'Quantity', field: 'quantity' },
    { name: 'Line Subtotal Excl', field: 'line_amount_excl' },
    { name: 'Line Subtotal Incl', field: 'line_amount_incl' },
    // { name: 'Billing Type', field: 'billing_type' },
  ];

  scheduleLineItems: any[] = [];
  scheduleLineItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    this.scheduleLineItems,
  );

  // schedule: NetworkPortalProducts[] = [
  //   {
  //     isp: 'Echo',
  //     external_product_code: 'FTTH-200-100-PP',
  //     name: 'FTTH 200Mbps 100GB',
  //     product_type: 'MRC',
  //     external_product_id: 'FTTH-200-100-PP',
  //     external_category: 'FTTH',
  //     date: '2024-10-30',
  //     description: 'FTTH 200Mbps 100GB',
  //     service_ref: '50SWP00001',
  //     price_excl: 100,
  //     price_incl: 115,
  //     currency: 'NAD',
  //     days_pro_rata: 0,
  //     days_full: 30,
  //     price_excl_pro_rata: 0,
  //     price_incl_pro_rata: 0,
  //   },
  // ];

  getNumberOfColumns(): number {
    return this.columns.length;
  }
  ngOnInit(): void {
    this.nbrs.getBillingRuns().subscribe((data) => {
      this.billingRuns = data;
      console.log(this.billingRuns);
    });

    this.selectedBillingRun = this.billingRuns[0];

    this.nis.getInvoices().subscribe((data) => {
      console.log(data);
    });

    this.nbrs
      .getBillingScheduleByBillingRunId(this.selectedBillingRun.id)
      .subscribe((data) => {
        console.log('bschedules', data);
        this.scheduleLineItems = data;
        this.scheduleLineItems$.next(this.scheduleLineItems);
      });
  }

  onBillingRunChange($event: any) {
    this.scheduleLineItemsLoading$.next(true);
    this.nbrs
      .getBillingScheduleByBillingRunId($event.value.id)
      .subscribe((data) => {
        console.log('bschedules', data);
        this.scheduleLineItems = data;
        this.scheduleLineItems$.next(this.scheduleLineItems);
        this.scheduleLineItemsLoading$.next(false);
      });
  }

  downloadBillingSchedule() {
    this.downloadLoading = true;
    this.nbrs
      .downloadBillingScheduleByBillingRunId(this.selectedBillingRun.id)
      .subscribe((response) => {
        this.downloadLoading = false;
        const mimetype = 'text/csv';
        const file = response.body;
        const defaultFileName = `Billing Schedule - ${this.selectedBillingRun.name}.csv`;
        const downloadFile = new File([file], defaultFileName, {
          type: mimetype,
        });
        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement('a');
        a.href = url;
        a.download = defaultFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  }

  openNewBillingRunDialog() {
    this.ds.open(DialogBillingRunComponent, {
      header: 'New Billing Run',
      width: '50%',
    });
  }

  calculateTotal(lineItem: any, taxType: string): string | number {
    if (taxType === 'excl') {
      this.exclTotal += lineItem.amount_excl * lineItem.quantity;

      return this.exclTotal;
    } else {
      return lineItem.amount_incl * lineItem.quantity;
    }
  }
}
