<form [formGroup]="formSubscriptionUpdate" (ngSubmit)="onSubscriptionUpdateSubmit()">
  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-6 text-center">
      <label class="text-gray-800 text-xl"> Current Billing Date: </label>
      <span class="text-500 text-xl block">
        {{ this.subscription.date_billing_next | date: "yyyy-MM-dd" }}
      </span>
    </div>
    <!-- Generate form fields dynamically based on a configuration object or using *ngFor if applicable -->
    <div class="field mb-4 col-12 md:col-6" *ngFor="let field of formFields">
      <label for="{{ field.name }}" class="text-gray-800 text-xl">
        {{ field.label }}
      </label>
      <p-calendar [id]="field.name" *ngIf="field.type === 'date'" [showIcon]="true" formControlName="date_billing_next"
        [showTime]="false" dateFormat="yy-mm-dd" [defaultDate]="defaultDate" [minDate]="minDate" [touchUI]="false"
        appendTo="body"></p-calendar>
    </div>
    <!-- submit -->
    <div class="field col-12">
      <p-button type="submit" label="Submit"></p-button>
    </div>
  </div>
</form>