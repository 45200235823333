<!-- billing runs -->
<div class="grid">
  <div class="col-12 md:col-3 md:align-items-center md:justify-content-between">
    <div class="font-medium text-3xl text-900 pl-2">Billing Runs</div>

    <div class="px-2 py-4 md:px-1 lg:px-2">
      <div class="flex flex-column md:flex-row justify-content-between align-items-center mb-4" *ngIf="false">
        <span class="p-input-icon-right mt-5 mb-2 md:mt-0 md:mb-0 w-full lg:w-25rem">
          <i class="pi pi-search text-gray-400"></i>
          <input type="text" class="p-inputtext w-full lg:w-25rem surface-50" placeholder="Search" />
        </span>
      </div>

      <div class="surface-card grid grid-nogutter border-round shadow-2">
        <div class="col-12 flex p-2 surface-100 border-round-top">
          <div class="p-2 flex-auto text-center md:text-left">
            <span class="text-700 block">Order Number</span>
            <span class="text-900 font-medium block mt-2">45123</span>
          </div>
          <p-divider align="center" layout="vertical" styleClass="h-full  mx-0 lg:mx-3 surface-border"></p-divider>
          <div class="p-2 flex-auto text-center md:text-left">
            <span class="text-700 block">Start Date</span>
            <span class="text-900 font-medium block mt-2">{{
              selectedBillingRun?.date_period_start
              }}</span>
          </div>
          <p-divider align="center" layout="vertical" styleClass="h-full  mx-0 lg:mx-3 surface-border"></p-divider>
          <div class="p-2 flex-auto text-center md:text-left">
            <span class="text-700 block">End Date</span>
            <span class="text-900 font-medium block mt-2">{{
              selectedBillingRun?.date_period_end
              }}</span>
          </div>
        </div>
        <!-- billing runs -->
        <p-listbox [options]="billingRuns || []" [(ngModel)]="selectedBillingRun"
          (onChange)="onBillingRunChange($event)" optionLabel="name" class="col-12">
          <ng-template let-billingRun pTemplate="item">
            <div class="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
              <div class="flex flex-column my-auto text-center align-items-center justify-content-center md:text-left">
                <span class="text-900 font-medium mt-3 mb-1 lg:mt-1">{{ billingRun.name }}
                </span>
                <span class="text-500 font-semibold text-sm mb-3">{{ billingRun.date_period_start }} to
                  {{ billingRun.date_period_end }}</span>
              </div>
            </div>
            <div class="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center"
              [style.border-radius]="'2.5rem'">
              <span class="text-white flex align-items-center justify-content-center border-circle mr-2"
                style="min-width: 2rem; min-height: 2rem" [ngClass]="{
                  'bg-green-500': billingRun.run_status === 'completed',
                  'bg-red-500': billingRun.run_status === 'failed',
                  'bg-yellow-500': billingRun.run_status === 'pending'
                }">
                <i class="pi" [ngClass]="{
                    'pi-check': billingRun.run_status === 'completed',
                    'pi-times': billingRun.run_status === 'failed',
                    'pi-clock': billingRun.run_status === 'pending'
                  }"></i>
              </span>
              <span [ngClass]="{
                  'text-green-500': billingRun.run_status === 'completed',
                  'text-red-500': billingRun.run_status === 'failed',
                  'text-yellow-500': billingRun.run_status === 'pending'
                }">{{ billingRun.date_run | date : "yyyy-MM-dd" }} -
                {{ billingRun.run_status | uppercase }}</span>
            </div>
          </ng-template>
        </p-listbox>

        <!-- actions -->
        <div class="col-12 p-0 flex border-top-1 surface-border">
          <a tabindex="0"
            class="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
            (click)="openNewBillingRunDialog()"><i class="pi pi-plus mr-2 mb-2 md:mb-0"></i>New Billing Run</a>
          <a pButton *ngIf="selectedBillingRun" tabindex="0"
            class="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-white hover:bg-primary-reverse hover:text-primary transition-duration-150 w-full"
            (click)="downloadBillingSchedule()" [loading]="downloadLoading" loadingIcon="pi pi-spin pi-spinner"
            label="Download Billing Schedule" icon="pi pi-download"></a>
          <!-- <a
            tabindex="0"
            class="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
            ><i class="pi pi-refresh mr-2 mb-2 md:mb-0"></i>Return</a
          > -->
          <!-- <a
            tabindex="0"
            class="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
            ><i class="pi pi-file mr-2 mb-2 md:mb-0"></i>View Invoice</a
          >
          <a
            tabindex="0"
            class="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
            style="border-bottom-right-radius: 6px"
            ><i class="pi pi-comment mr-2 mb-2 md:mb-0"></i>Write a Review</a
          > -->
        </div>
      </div>
    </div>
  </div>

  <!-- billing history -->
  <div class="col-8 md:col-9">
    <div class="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
      <div class="flex-auto font-medium text-3xl text-900 mb-4">
        Billing Schedule
      </div>
    </div>
    <div class="flex-1 border-round shadow-2 p-3">
      <p-table #billingScheduleTable [value]="(scheduleLineItems$ | async) || []" sortField="representative.name"
        sortMode="single" [scrollable]="true" scrollHeight="400px" rowGroupMode="subheader" groupRowsBy="billing_type"
        [tableStyle]="{ 'min-width': '60rem' }" [loading]="(scheduleLineItemsLoading$ | async) || false">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of columns">{{ col.name }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="groupheader" let-item>
          <tr pRowGroupHeader>
            <td [attr.colspan]="getNumberOfColumns()" class="text-center">
              <span class="font-bold ml-2">{{ item.billing_type }}</span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr>
            <td *ngFor="let col of columns" class="white-space-nowrap">
              {{ item[col.field] }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div *ngIf="selectedBillingRun" class="flex flex-column align-items-end justify-content-between text-right">
            <span class="">Total (Excl): 1000.00</span>
            <span>Total (Incl): 1150.00</span>
          </div>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="getNumberOfColumns()" class="text-center">
              <div class="text-center p-4">
                <span class="pi pi-info-circle text-yellow-500" style="font-size: 3rem"></span>
                <p class="font-bold text-lg">No billing schedule items</p>
                <p>Please select a billing run to view the schedule.</p>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>